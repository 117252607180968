import cx from 'classnames'
import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {updateGlobalSettings} from 'redux/action/globalSettingsAction'
import {ReactComponent as DoubleArrow} from 'style/asset/double-arrow.svg'
import {ReactComponent as SingleArrow} from 'style/asset/single-arrow.svg'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'

const TablePagination = (props) => {
  const {
    classes,
    count,
    page,
    onChangePage,
    rowsPerPageOptions,
    customRowsPerPageOptions,
    rowsPerPage,
    updateGlobalSettings,
    meta,
  } = props

  const handleChangeRowsPerPage = (option) => (e) => {
    updateGlobalSettings({rowsPerPage: option})
  }

  const handleChangePage = (newPage) => (e) => {
    if (lastPage !== 0 && meta?.limit) {
      const maxPage = Math.floor(count / meta.limit)
      const newPageNumber = newPage < 0 ? 0 : newPage > maxPage ? maxPage : newPage
      onChangePage(newPageNumber)
    }
  }

  const renderPageNumbers = () => {
    if (meta?.limit) {
      const pages = []
      const maxPage = Math.ceil(count / meta.limit)

      const finalPages = []

      // add one to solve index issue (index starts 0, page 1)
      // subtract two for edge page number
      const leftEdgePageNumber = page + 1 - 2
      const rightEdgePageNumber = maxPage - page - 2

      // returns 5 pages, 2 from left, current and 2 from right
      for (let i = leftEdgePageNumber; i <= page + 1 + 2; i++) {
        if (i > 0 && i <= maxPage) {
          if (!finalPages.includes(i)) {
            finalPages.push(i)
          }
        }
      }

      // left side of pagination (pages and dots)
      if (leftEdgePageNumber === 2) {
        finalPages.unshift(1)
      } else if (leftEdgePageNumber === 3) {
        finalPages.unshift(...[1, 2])
      } else if (leftEdgePageNumber > 3) {
        finalPages.unshift(...[1, 'threeDots'])
      }

      // right side of pagination (pages and dots)
      if (rightEdgePageNumber === 2) {
        finalPages.push(maxPage)
      } else if (rightEdgePageNumber === 3) {
        finalPages.push(...[maxPage - 1, maxPage])
      } else if (rightEdgePageNumber > 3) {
        finalPages.push(...['threeDots', maxPage])
      }

      finalPages.forEach((pageNumber, index) => {
        if (pageNumber === 'threeDots') {
          pages.push(
            <div className={classes.page} key={`threeDots${index}`}>
              ...
            </div>
          )
        } else {
          pages.push(
            <div
              className={cx(
                classes.page,
                page === pageNumber - 1 ? classes.pageSettingOptionSelected : ''
              )}
              key={index}
              onClick={handleChangePage(pageNumber - 1)}
            >
              {pageNumber}
            </div>
          )
        }
      })

      return pages
    }
  }

  const lastPage = meta?.limit && Math.ceil(count / meta.limit)

  const rowOptions = customRowsPerPageOptions ? customRowsPerPageOptions : rowsPerPageOptions

  return (
    <div className={classes.tablePaginationContainer}>
      <div className={classes.arrowBoxLeft} onClick={handleChangePage(0)}>
        <DoubleArrow />
      </div>
      <div className={classes.arrowBoxLeft} onClick={handleChangePage(page - 1)}>
        <SingleArrow />
      </div>
      <div className={classes.pageInfo}>
        <div className={classes.pageInfoPages}>
          <Trans>Page</Trans>
          {` 1 - ${lastPage === 0 ? 1 : lastPage ? lastPage : 1}`}
        </div>
        <div className={classes.pageInfoTotalCount}>
          <Trans>Number of items</Trans>
          {` ${count ? count : 0}`}
        </div>
      </div>

      <div className={classes.pages}>{renderPageNumbers()}</div>

      <div className={classes.pageSetting}>
        <div className={classes.pageSettingText}>
          <div>
            <Trans>Items</Trans>
          </div>
          <div>
            <Trans>on page</Trans>
          </div>
        </div>
        <div className={classes.pageSettingOptions}>
          {rowOptions.map((option, index) => (
            <span
              key={index}
              onClick={!customRowsPerPageOptions ? handleChangeRowsPerPage(option) : null}
              className={cx(
                classes.pageSettingsOption,
                option === rowsPerPage ? classes.pageSettingOptionSelected : ''
              )}
            >
              {option}
            </span>
          ))}
        </div>
      </div>
      <div className={classes.arrowBoxRight} onClick={handleChangePage(page + 1)}>
        <SingleArrow />
      </div>
      <div className={classes.arrowBoxRight} onClick={handleChangePage(lastPage)}>
        <DoubleArrow />
      </div>
    </div>
  )
}

TablePagination.propTypes = {
  classes: PropTypes.object,
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  updateGlobalSettings: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSettings,
    },
    dispatch
  )
}

export default compose(
  withStyles(materialStyle),
  connect((store) => {
    return {
      rowsPerPageOptions: store.globalSettings.rowsPerPageOptions,
      rowsPerPage: store.globalSettings.rowsPerPage,
    }
  }, mapDispatchToProps)
)(TablePagination)
