import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import PropTypes from 'prop-types'
import React from 'react'
import {CircularProgress, TextField} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from '@material-ui/core/styles/withStyles'
import {Search} from '@material-ui/icons'

const TextInput = (props) => {
  const {
    classes,
    input,
    meta,
    type,
    helperText,
    hideNumberArrows = false,
    variant = 'outlined',
    showHelperText = true,
    onChange,
    label,
    multiline = false,
    className,
    loading,
    filters,
    fullWidth = true,
    inLineInput,
    noInlineFlex = false,
    hasSearch,
    longLabel = false,
    inputJustifyRight = false,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  const inputClasses = cx({
    hideNumberArrows: true,
  })

  return (
    <div className={cx(classes.fullWidth, !filters && classes.inLineField)}>
      {label && (
        <div
          className={cx(
            classes.fieldLabel,
            filters && classes.filtersFieldLabel,
            inLineInput && classes.inLineFieldLabel,
            longLabel && classes.longFieldLabel
          )}
        >
          {label}
        </div>
      )}
      <TextField
        variant={variant}
        fullWidth
        type={type}
        className={cx(
          classes.inputStyles,
          classes.helperText,
          !multiline && classes.inputPadding,
          (input?.type === 'number' || type === 'number') && inputClasses,
          classes.adornments,
          classes.fieldShadow,
          inputJustifyRight && classes.inputJustifyRight,
          inLineInput && !noInlineFlex && classes.inLineFlexGrow,
          className
        )}
        {...input}
        onChange={(e) => {
          input?.onChange(e)
          onChange && onChange(e)
        }}
        InputProps={{
          endAdornment: loading ? (
            <InputAdornment position="end" className={classes.loadingEndAdornment}>
              <CircularProgress color="primary" />
            </InputAdornment>
          ) : hasSearch ? (
            <InputAdornment position="end" className={classes.loadingEndAdornment}>
              <Search color={'disabled'} fontSize={'small'} />
            </InputAdornment>
          ) : null,
        }}
        error={!!errorMessage}
        helperText={errorHelperText}
        multiline={multiline}
        {...rest}
      />
    </div>
  )
}

TextInput.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  rest: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
}

export default withStyles(fieldStyle)(TextInput)
