import detailPageStyle from 'component/detailPageStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import Table from 'component/material/table/Table'
import {DATE_TIME_DISPLAY_FORMAT} from 'helper/constants'
import moment from 'moment/moment'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {getChangeLog} from 'redux/action/callAction'
import {Trans} from '@lingui/macro'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

const CallChangeLog = (props) => {
  const {classes, getChangeLog, changeLogLoading, changeLog, changeLogMeta} = props

  let {id} = useParams()

  const [changeLogTableSort, setChangeLogTableSort] = useState({
    orderBy: 'id',
    orderDirection: 'DESC',
  })
  const [changeLogPage, setChangeLogPage] = useState(0)

  const handleChangeChangeLogPage = (newPage) => {
    setChangeLogPage(newPage)
  }

  const handleChangeLogTableSort = (sort) => {
    setChangeLogTableSort(sort)
  }

  const changeLogColumns = [
    {
      name: 'field',
      label: <Trans>Field</Trans>,
    },
    {
      name: 'step_before',
      label: <Trans>Before</Trans>,
      render: (val) => {
        if (val?.length >= 54) {
          return (
            <CustomTooltip title={val} className={classes.statusDescriptionInChangeLogWrapper}>
              <span className={classes.statusDescriptionInChangeLog}>{val}</span>
            </CustomTooltip>
          )
        } else {
          return val
        }
      },
    },
    {
      name: 'step_after',
      label: <Trans>After</Trans>,
      render: (val) => {
        if (val?.length >= 54) {
          return (
            <CustomTooltip title={val} className={classes.statusDescriptionInChangeLogWrapper}>
              <span className={classes.statusDescriptionInChangeLog}>{val}</span>
            </CustomTooltip>
          )
        } else {
          return val
        }
      },
    },
    {
      name: 'username',
      label: <Trans>Username</Trans>,
    },
    {
      name: 'datetime',
      label: <Trans>Timestamp</Trans>,
      render: (val) => val && moment(val).format(DATE_TIME_DISPLAY_FORMAT),
    },
  ]

  useEffect(() => {
    getChangeLog(id)
  }, [])

  return (
    <BoxFullWidth style={{paddingTop: 40, overflow: 'auto'}}>
      <Typography align={'center'} variant={'h6'} style={{fontWeight: 600, marginBottom: 16}}>
        <Trans>Changelog</Trans>
      </Typography>
      <Table
        data={changeLog}
        columns={changeLogColumns}
        loading={changeLogLoading}
        onTableColumnSort={handleChangeLogTableSort}
        tableSort={changeLogTableSort}
        page={changeLogPage}
        meta={changeLogMeta}
        onChangePage={handleChangeChangeLogPage}
        customRowsPerPageOptions={[10]}
      />
    </BoxFullWidth>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({getChangeLog}, dispatch)
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      changeLogLoading: store.call.changeLogLoading,
      changeLog: store.call.changeLog,
      changeLogMeta: store.call.changeLogMeta,
    }
  }, mapDispatchToProps)
)(CallChangeLog)
