import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import FormHelperText from '@material-ui/core/FormHelperText'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomPhoneInput = (props) => {
  const {
    classes,
    label,
    input,
    meta,
    onChange,
    onFocus,
    variant = 'outlined',
    type = 'tel',
    showHelperText = true,
    helperText,
    name,
    id,
    disabled,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <div className={cx(classes.fullWidth, classes.inLineField)}>
      {label && <div className={cx(classes.fieldLabel)}>{label}</div>}
      <BoxFullWidth>
        <BoxFullWidth>
          <PhoneInput
            {...input}
            id={id}
            name={name}
            value={input.value}
            onChange={(value, data, e, formatted) => {
              input?.onChange(value)
              onChange && onChange(value, data, e, formatted)
            }}
            onFocus={(e, data) => {
              onFocus && onFocus(e, data)
            }}
            containerStyle={{
              width: '100%',
            }}
            containerClass={!disabled && classes.containerClass}
            inputStyle={{
              borderRadius: '4px',
              width: '100%',
              height: 30,
              fontSize: 12,
              fontFamily: 'Inter',
              boxShadow: '0 2px 6px 0 rgb(103 103 139 / 10%)',
              borderColor: 'rgba(0, 0, 0, 0.26)',
            }}
            label={''}
            country={'cz'}
            disabled={disabled}
            {...rest}
          />
        </BoxFullWidth>
        {showHelperText && (
          <BoxFullWidth>
            <FormHelperText className={classes.formHelperText} error={!!errorMessage}>
              {errorHelperText}
            </FormHelperText>
          </BoxFullWidth>
        )}
      </BoxFullWidth>
    </div>
  )
}

export default withStyles(fieldStyle)(CustomPhoneInput)
