import LoginForm from 'component/LoginPage/LoginForm'
import loginPageStyle from 'component/LoginPage/loginPageStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PaperFlexGrow from 'component/material/PaperFlexGrow'
import PropTypes from 'prop-types'
import React from 'react'
import {ReactComponent as LKQLogo} from 'style/asset/lkq.svg'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const LoginPage = (props) => {
  const {classes} = props
  return (
    <GridContainer direction="row" justifyContent="center" alignItems="center">
      <GridItem xs={12} sm={8} md={6} lg={4} style={{zIndex: '1'}}>
        <PaperFlexGrow elevation={5}>
          <GridContainer direction="column" justifyContent="center" alignItems="center">
            <BoxFullWidth px={20} py={12} className={classes.loginBoxPadding}>
              <GridContainer justifyContent="center" alignItems="center">
                <Box pb={8} className={classes.lkqLogo}>
                  <LKQLogo />
                </Box>
              </GridContainer>

              <LoginForm />
            </BoxFullWidth>
          </GridContainer>
        </PaperFlexGrow>
      </GridItem>
    </GridContainer>
  )
}

LoginPage.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(loginPageStyle)(LoginPage)
