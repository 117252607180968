import detailPageStyle from 'component/detailPageStyle'
import TextInput from 'component/field/TextInput'
import CustomTooltip from 'component/material/CustomTooltip'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {isEmptyObject} from 'helper/functions'
import {required} from 'helper/validations'
import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {getOrderInfo, sendOrderInfo} from 'redux/action/callAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'

const EstablishmentOrderEmail = (props) => {
  const {classes, getOrderInfo, sendOrderInfo, onClose} = props

  const [orderInfo, setOrderInfo] = useState({})
  const [orderInfoLoading, setOrderInfoLoading] = useState(false)

  let {id} = useParams()

  const prepareInitialValues = () => {
    if (!isEmptyObject(orderInfo)) {
      return {
        recipient_email: orderInfo.recipient_email,
        email_subject: orderInfo.email_subject,
        email_body: orderInfo.email_draft,
      }
    }
  }

  const prepareData = (values) => {
    return {
      ...values,
      recipient_email_cc: values.recipient_email_cc?.split(',') || [],
    }
  }

  const handleSubmit = (values) => {
    if (id && id !== 'new') {
      const data = prepareData(values)
      sendOrderInfo(id, data).then(() => onClose())
    }
  }

  const loadingBoxStyle = {
    width: 'calc(100% + 8px)',
    marginLeft: '-4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 1,
    backdropFilter: 'blur(2px)',
  }

  useEffect(() => {
    if (id && id !== 'new') {
      setOrderInfoLoading(true)
      getOrderInfo(id)
        .then((res) => {
          setOrderInfo(res)
        })
        .finally(() => setOrderInfoLoading(false))
    }
  }, [])

  return (
    <Form onSubmit={handleSubmit} initialValues={prepareInitialValues()}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer style={{position: 'relative'}}>
              {orderInfoLoading && (
                <Box style={loadingBoxStyle}>
                  <CircularProgress />
                </Box>
              )}
              <GridItem container xs={12}>
                <Field
                  disabled={formProps.submitting}
                  name="recipient_email"
                  label={<Trans>Recipient</Trans>}
                  component={TextInput}
                  validate={required}
                  autoComplete={'recipient_email-off'}
                />
              </GridItem>
              <GridItem container xs={12}>
                <Field
                  disabled={formProps.submitting}
                  name="recipient_email_cc"
                  label={
                    <CustomTooltip title={<Trans>Separate e-mails for copy with commas ","</Trans>}>
                      <span className={classes.tooltipLabel}>
                        <Trans>Copy</Trans>
                      </span>
                    </CustomTooltip>
                  }
                  component={TextInput}
                  autoComplete={'recipient_email_cc-off'}
                />
              </GridItem>
              <GridItem container xs={12}>
                <Field
                  disabled={formProps.submitting}
                  name="email_subject"
                  label={<Trans>Subject</Trans>}
                  component={TextInput}
                  autoComplete={'email_subject-off'}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  disabled={formProps.submitting}
                  name="email_body"
                  label={<Trans>Body</Trans>}
                  component={TextInput}
                  multiline
                  minRows={10}
                  autoComplete={'email_body-off'}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent={'flex-end'} style={{paddingTop: 32}}>
              <PrimaryButton
                fullWidth={false}
                textPadding={0}
                text={<Trans>Send</Trans>}
                size={'small'}
                type={'submit'}
                disabled={formProps.submitting}
              />
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrderInfo,
      sendOrderInfo,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(EstablishmentOrderEmail)
