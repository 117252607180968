import App from 'App/App'
import React from 'react'
import {Provider} from 'react-redux'
import store from 'redux/store'
import theme from 'theme/theme'
import MomentUtils from '@date-io/moment'
import {ThemeProvider} from '@material-ui/core/styles'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'

const AppProvider = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default AppProvider
