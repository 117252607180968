import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import {DATE_TIME_DISPLAY_FORMAT} from 'helper/constants'
import PropTypes from 'prop-types'
import React from 'react'
import {ReactComponent as Calendar} from 'style/asset/calendar.svg'
import {Trans} from '@lingui/macro'
import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from '@material-ui/core/styles/withStyles'
import {DateTimePicker} from '@material-ui/pickers'

const DatetimePickerInput = (props) => {
  const {
    classes,
    helperText,
    input,
    meta,
    label,
    variant = 'outlined',
    showHelperText = true,
    clear,
    showStartAdornment = true,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <div className={cx(classes.fullWidth, classes.inLineField)}>
      {label && <div className={cx(classes.fieldLabel)}>{label}</div>}
      <div className={classes.datePickerInput}>
        <DateTimePicker
          {...input}
          {...rest}
          minutesStep={5}
          value={input?.value ? input.value : null}
          inputVariant={variant}
          autoOk={true}
          ampm={false}
          format={DATE_TIME_DISPLAY_FORMAT}
          error={!!errorMessage}
          helperText={errorHelperText}
          invalidDateMessage={<Trans>Invalid Date Format</Trans>}
          maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
          minDateMessage={<Trans>Date should not be before minimal date</Trans>}
          cancelLabel={<Trans>Close</Trans>}
          clearLabel={<Trans>Reset</Trans>}
          okLabel={<Trans>Ok</Trans>}
          InputProps={{
            startAdornment: showStartAdornment ? (
              <InputAdornment position={'start'} className={classes.datePickerAdornment}>
                <Calendar />
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
      </div>
    </div>
  )
}

DatetimePickerInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  variant: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  invalidDateMessage: PropTypes.node,
  maxDateMessage: PropTypes.node,
  minDateMessage: PropTypes.node,
  cancelLabel: PropTypes.node,
  okLabel: PropTypes.node,
}

export default withStyles(fieldStyle)(DatetimePickerInput)
