import {LS_LOGGED_USER, LS_SESSION_ID_NAME} from 'helper/configConstants'
import endpoints from 'helper/endpoints'
import {
  fireErrorToast,
  fireSuccessToast,
  globalApiErrorHandler,
  redirectTo,
  setItemToStorage,
} from 'helper/functions'
import httpClient from 'helper/httpClient'
import React from 'react'
import {
  GET_CURRENT_USER_FAIL,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  PUT_CHANGE_PASSWORD_FAIL,
  PUT_CHANGE_PASSWORD_REQUEST,
  PUT_CHANGE_PASSWORD_SUCCESS,
  PUT_CURRENT_USER_FAIL,
  PUT_CURRENT_USER_REQUEST,
  PUT_CURRENT_USER_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const login = (data) => {
  return (dispatch) => {
    dispatch({type: LOGIN_REQUEST})
    return httpClient
      .post(endpoints.login, data)
      .then((res) => {
        dispatch({type: LOGIN_SUCCESS})
        if (res.status === 200) {
          setItemToStorage(LS_SESSION_ID_NAME, res.headers['session-id'])
          setItemToStorage(LS_LOGGED_USER, res.data)
          fireSuccessToast(<Trans>Successfully logged in.</Trans>)
          redirectTo('/')
        }
      })
      .catch((err) => {
        dispatch({type: LOGIN_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({type: LOGOUT_REQUEST})
    return httpClient
      .del(endpoints.logout)
      .then((res) => {
        if (res.status === 204) {
          localStorage.removeItem(LS_SESSION_ID_NAME)
          localStorage.removeItem(LS_LOGGED_USER)
          redirectTo('/login')
          dispatch({type: LOGOUT_SUCCESS, data: res.data.user})
        }
      })
      .catch((err) => {
        dispatch({type: LOGOUT_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const forgottenPassword = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.forgottenPassword, data)
      .then((res) => {
        return true
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const resetPassword = (token, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.resetPassword}/${token}`, data)
      .then((res) => {
        return true
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

// my account from auth service
export const getCurrentUser = () => {
  return (dispatch) => {
    dispatch({type: GET_CURRENT_USER_REQUEST})
    return httpClient
      .get(endpoints.currentUser)
      .then((res) => {
        dispatch({type: GET_CURRENT_USER_SUCCESS, data: res.data})
      })
      .catch((err) => {
        dispatch({type: GET_CURRENT_USER_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const putCurrentUser = (data) => {
  return (dispatch) => {
    dispatch({type: PUT_CURRENT_USER_REQUEST})
    return httpClient
      .put(endpoints.currentUser, data)
      .then((res) => {
        dispatch({type: PUT_CURRENT_USER_SUCCESS, data: res.data})
      })
      .catch((err) => {
        dispatch({type: PUT_CURRENT_USER_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const changePassword = (data) => {
  return (dispatch) => {
    dispatch({type: PUT_CHANGE_PASSWORD_REQUEST})
    return httpClient
      .post(endpoints.currentUser + '/change-password', data)
      .then((res) => {
        dispatch({type: PUT_CHANGE_PASSWORD_SUCCESS, data: res.data})
        fireSuccessToast(<Trans>User password updated.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: PUT_CHANGE_PASSWORD_FAIL})
        fireErrorToast(<Trans>User password update failed.</Trans>)
        return globalApiErrorHandler(err)
      })
  }
}

export const welcomeUser = (id, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.user}/${id}/welcome`, data)
      .then((res) => {
        fireSuccessToast(<Trans>Welcome e-mail was sent.</Trans>)
        return res.data
      })
      .catch((err) => {
        fireErrorToast(<Trans>Welcome e-mail was not sent.</Trans>)
        return globalApiErrorHandler(err)
      })
  }
}
