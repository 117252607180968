import {combineReducers} from 'redux'
import authReducer from 'redux/reducer/authReducer'
import callReducer from 'redux/reducer/callReducer'
import globalSettingsReducer from 'redux/reducer/globalSettingsReducer'

export default combineReducers({
  auth: authReducer,
  call: callReducer,
  globalSettings: globalSettingsReducer,
})
