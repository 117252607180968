import {DATE_FORMAT} from 'helper/constants'
import moment from 'moment'
import React from 'react'
import {Trans} from '@lingui/macro'

/// undefined == no error for the react-final-form ///

export const required = (value) => {
  return value ? undefined : <Trans>Required field</Trans>
}

// form level validation of identity of passwords
export const samePasswordValidation = (values) => {
  if (values.new_password !== values.password_confirmation) {
    return {password_confirmation: <Trans>Passwords must be the same</Trans>}
  }
  return undefined
}

export const validateVin = (value) => {
  const val = value?.toUpperCase()
  if (!!val) {
    if (/[A-Z0-9]/g.test(val)) {
      if (val.includes('I') || val.includes('O') || val.includes('Q')) {
        return <Trans>Value can not contain I,O or Q</Trans>
      } else if (val?.length !== 17) {
        return <Trans>VIN must be 17 characters long</Trans>
      }
    } else if (/[ ]/g.test(val)) {
      return <Trans>Empty space is not valid character</Trans>
    } else {
      return <Trans>Value is not valid VIN</Trans>
    }
  }
  return undefined
}

export const validateRz = (value) => {
  const val = value?.toUpperCase()
  if (!!val) {
    if (/[^A-Z0-9]/g.test(val)) {
      if (/[ ]/g.test(val)) {
        return <Trans>Empty space is not valid character</Trans>
      } else {
        return <Trans>Value is not valid RZ</Trans>
      }
    }
  }
  return undefined
}

export const validatePower = (value) => {
  if (value && value < 10 && parseInt(value, 10) !== 0) {
    return <Trans>Power must be between 10 and 1000, or 0 if not applicable</Trans>
  }
  return undefined
}

export const validateEngineCapacity = (value) => {
  if (value && value < 500 && parseInt(value, 10) !== 0) {
    return <Trans>Engine capacity must be between 500 and 9999, or 0 if not applicable</Trans>
  }
  return undefined
}
