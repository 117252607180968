import PasswordInput from 'component/field/PasswordInput'
import fieldStyle from 'component/field/fieldStyle'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {fireErrorToast, fireSuccessToast} from 'helper/functions'
import {required, samePasswordValidation} from 'helper/validations'
import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {resetPassword} from 'redux/action/authAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const CreatePasswordForm = (props) => {
  const {classes, handleCloseCreateNewPassword, resetPasswordToken, resetPassword} = props

  const [resetLoading, setResetLoading] = useState(false)

  const handleSubmit = (values) => {
    setResetLoading(true)
    resetPassword(resetPasswordToken, {password: values.password})
      .then((res) => {
        fireSuccessToast(<Trans>New password was set.</Trans>)
      })
      .catch((err) => fireErrorToast(<Trans>New password failed to be set.</Trans>))
      .finally(() => {
        handleCloseCreateNewPassword()
        setResetLoading(false)
      })
  }

  return (
    <Box p={5}>
      <Form onSubmit={handleSubmit} validate={samePasswordValidation}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={6} className={classes.smallGridPadding}>
                  <Field
                    name="password"
                    label={<Trans>Password</Trans>}
                    component={PasswordInput}
                    validate={required}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} className={classes.smallGridPadding}>
                  <Field
                    name="password_confirmation"
                    label={<Trans>Password again</Trans>}
                    component={PasswordInput}
                    validate={required}
                  />
                </GridItem>
                <GridItem container justifyContent={'flex-end'}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={resetLoading}
                    size={'large'}
                    type="submit"
                    formSubmit={true}
                    textPadding={0}
                    text={<Trans>Create</Trans>}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPassword,
    },
    dispatch
  )
}

export default compose(
  withStyles(fieldStyle),
  connect(null, mapDispatchToProps)
)(CreatePasswordForm)
