import endpoints from 'helper/endpoints'
import {fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import React from 'react'
import {
  GET_CALL_FAIL,
  GET_CALL_LIST_FAIL,
  GET_CALL_LIST_REQUEST,
  GET_CALL_LIST_SUCCESS,
  GET_CALL_REQUEST,
  GET_CALL_SUCCESS,
  GET_CAR_HIS_FAIL,
  GET_CAR_HIS_REQUEST,
  GET_CAR_HIS_SUCCESS,
  GET_CHANGE_FAIL,
  GET_CHANGE_REQUEST,
  GET_CHANGE_SUCCESS,
  GET_PHONE_HIS_FAIL,
  GET_PHONE_HIS_REQUEST,
  GET_PHONE_HIS_SUCCESS,
  GET_STATUS_LIST_FAIL,
  GET_STATUS_LIST_REQUEST,
  GET_STATUS_LIST_SUCCESS,
  POST_CAR_SEARCH_FAIL,
  POST_CAR_SEARCH_REQUEST,
  POST_CAR_SEARCH_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const getCallList = (limit = 1000, offset = 0, meta = {}, filters = {}, search) => {
  return (dispatch) => {
    dispatch({type: GET_CALL_LIST_REQUEST})
    let f = {}
    if (filters?.inProcess) f.in_process = true
    if (filters?.closed) f.closed = true
    if (filters?.myCalls) f.my_calls = true
    if (search) f.search = search

    return httpClient
      .get(endpoints.calls, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        ...f,
      })
      .then((res) => {
        dispatch({type: GET_CALL_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CALL_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getCall = (id) => {
  return (dispatch) => {
    dispatch({type: GET_CALL_REQUEST})
    return httpClient
      .get(`${endpoints.calls}/${id}`)
      .then((res) => {
        dispatch({type: GET_CALL_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CALL_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const createCall = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.calls, data)
      .then((res) => {
        fireSuccessToast(<Trans>New call created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const patchCall = (id, data) => {
  return (dispatch) => {
    dispatch({type: GET_CALL_REQUEST})
    return httpClient
      .patch(endpoints.calls + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Call updated.</Trans>)
        dispatch({type: GET_CALL_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CALL_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getStatusList = (limit = 100, offset = 0, meta = {}) => {
  return (dispatch) => {
    dispatch({type: GET_STATUS_LIST_REQUEST})
    return httpClient
      .get(endpoints.statuses, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_STATUS_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_STATUS_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const carSearch = (data) => {
  return (dispatch) => {
    dispatch({type: POST_CAR_SEARCH_REQUEST})
    return httpClient
      .post(endpoints.carSearch, data)
      .then((res) => {
        dispatch({type: POST_CAR_SEARCH_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: POST_CAR_SEARCH_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const createCar = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.cars, data)
      .then((res) => {
        fireSuccessToast(<Trans>New car created.</Trans>)
        dispatch({type: POST_CAR_SEARCH_SUCCESS, data: {...res.data}})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getDriverInfo = (phone) => {
  return (dispatch) => {
    return httpClient
      .get(endpoints.driverInfo, {phone_number: phone})
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const getOrderPdf = (id) => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.calls}/${id}/order-form`,
        {},
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'blob',
        }
      )
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const resetCarFields = () => {
  return (dispatch) => {
    dispatch({type: POST_CAR_SEARCH_SUCCESS, data: {}})
  }
}

export const resetCallData = () => {
  return (dispatch) => {
    dispatch({type: GET_CALL_SUCCESS, data: {}})
  }
}

export const getPhoneHistory = (id, limit = 1000, offset = 0, meta = {}) => {
  return (dispatch) => {
    dispatch({type: GET_PHONE_HIS_REQUEST})
    return httpClient
      .get(`${endpoints.calls}/${id}/phone-history`, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_PHONE_HIS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PHONE_HIS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getCarHistory = (id, limit = 1000, offset = 0, meta = {}) => {
  return (dispatch) => {
    dispatch({type: GET_CAR_HIS_REQUEST})
    return httpClient
      .get(`${endpoints.calls}/${id}/car-history`, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_CAR_HIS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CAR_HIS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getOrderInfo = (id) => {
  return (dispatch) => {
    return httpClient
      .get(`${endpoints.calls}/${id}/order-info`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const sendOrderInfo = (id, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.calls}/${id}/send-order-info`, data)
      .then((res) => {
        fireSuccessToast(<Trans>E-mail was sent.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getDriverOrderInfo = (id) => {
  return (dispatch) => {
    return httpClient
      .get(`${endpoints.calls}/${id}/driver-order-info`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const sendDriverOrderInfo = (id, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.calls}/${id}/send-driver-order-info`, data)
      .then((res) => {
        fireSuccessToast(<Trans>E-mail was sent.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getChangeLog = (id) => {
  return (dispatch) => {
    dispatch({type: GET_CHANGE_REQUEST})
    return httpClient
      .get(`${endpoints.calls}/${id}/change-logs`)
      .then((res) => {
        dispatch({type: GET_CHANGE_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CHANGE_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const triggerReport = (name) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.report, {name: name})
      .then((res) => {
        fireSuccessToast(<Trans>Report creation was triggered</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const cancelCall = (id) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.calls}/${id}/cancel`)
      .then((res) => {
        fireSuccessToast(<Trans>Call was canceled</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
