import TextInput from 'component/field/TextInput'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {mapErrorResponseToForm} from 'helper/functions'
import React from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {patchCall} from 'redux/action/callAction'
import {Trans} from '@lingui/macro'

const CallPageNoteEdit = (props) => {
  const {patchCall, data, onClose, getCalls} = props

  const handleSubmit = (values) => {
    return patchCall(data.id, {note: values?.note})
      .then(() => {
        getCalls()
        onClose()
      })
      .catch((err) => {
        return mapErrorResponseToForm(err)
      })
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={{note: data?.note}}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer style={{position: 'relative'}}>
              <GridItem xs={12}>
                <Field
                  disabled={formProps.submitting}
                  name="note"
                  label={<Trans>Note</Trans>}
                  component={TextInput}
                  multiline
                  minRows={4}
                  filters={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent={'flex-end'} style={{paddingTop: 32}}>
              <PrimaryButton
                fullWidth={false}
                textPadding={0}
                text={<Trans>Save</Trans>}
                size={'small'}
                type={'submit'}
                disabled={formProps.submitting}
              />
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      patchCall,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(CallPageNoteEdit)
