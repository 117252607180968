import {resolveEnv} from 'helper/functions'
import React from 'react'
import {ReactComponent as CZ} from 'style/asset/flags/cz.svg'
import {ReactComponent as GB} from 'style/asset/flags/gb.svg'
import {ReactComponent as HU} from 'style/asset/flags/hu.svg'
import {ReactComponent as PL} from 'style/asset/flags/pl.svg'
import {ReactComponent as RO} from 'style/asset/flags/ro.svg'
import {ReactComponent as SK} from 'style/asset/flags/sk.svg'
import {ReactComponent as UA} from 'style/asset/flags/ua.svg'

export const ENVIRONMENT = process.env.NODE_ENV
export const PUBLIC_API_KEY = resolveEnv('REACT_APP_PUBLIC_API_KEY')
export const GATEWAY_URL = resolveEnv('REACT_APP_GATEWAY_URL')

export const RESET_PSW_URL = resolveEnv('REACT_APP_RESET_PASSWORD_URL')

export const ODOMETER_THRESHOLD = resolveEnv('REACT_APP_ODOMETER_THRESHOLD') || 120000
export const ACC_NAMES = resolveEnv('REACT_APP_ACC_NAMES')

// database date format
export const DB_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_TIME_DISPLAY_FORMAT = 'DD.MM.YYYY HH:mm'
export const DB_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ'

export const LANGUAGE_LIST = [
  {value: 'cs', icon: <CZ />},
  // {value: 'sk', icon: <SK />},
  // {value: 'pl', icon: <PL />},
  // {value: 'hu', icon: <HU />},
  {value: 'en', icon: <GB />},
  // {value: 'uk', icon: <UA />},
  // {value: 'ro', icon: <RO />},
]
