import catalogCs from 'App/locale/cs/messages'
import catalogEn from 'App/locale/en/messages'
import CallDetail from 'component/CallPage/CallDetail'
import CallPage from 'component/CallPage/CallPage'
import toastStyle from 'component/toast/toastStyle'
import browserHistory from 'helper/history'
import PrivateRoute from 'layout/PrivateLayout/PrivateRoute'
import PublicRoute from 'layout/PublicLayout/PublicRoute'
import moment from 'moment'
import 'moment/min/locales'
import LoginPage from 'page/LoginPage'
import ResetPasswordPage from 'page/ResetPasswordPage'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Redirect, Router, Switch} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {compose} from 'redux'
import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import withStyles from '@material-ui/core/styles/withStyles'

function App(props) {
  const {classes, globalSettings} = props

  const activeLang = globalSettings.currentLanguage ? globalSettings.currentLanguage : 'en'

  moment.locale(activeLang)

  i18n.load({
    en: catalogEn.messages,
    cs: catalogCs.messages,
  })

  i18n.activate(activeLang)

  return (
    <I18nProvider i18n={i18n}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={2500}
        className={classes.containerStyle}
      />
      <Router history={browserHistory}>
        <Switch>
          {/* PUBLIC ROUTES */}
          <PublicRoute path="/login" component={LoginPage} />
          <PublicRoute exact path="/reset-password/:token" component={ResetPasswordPage} />

          {/* PRIVATE ROUTES */}
          <PrivateRoute exact path="/" component={CallPage} />
          <PrivateRoute exact path="/call/:id" component={CallDetail} />

          <Redirect to="/login" />
        </Switch>
      </Router>
    </I18nProvider>
  )
}

App.propTypes = {
  globalSettings: PropTypes.object,
  classes: PropTypes.object,
}

export default compose(
  withStyles(toastStyle),
  connect((store) => {
    return {
      globalSettings: store.globalSettings,
    }
  })
)(App)
