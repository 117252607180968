import React from 'react'
import {Trans} from '@lingui/macro'

const columns = [
  [
    {
      name: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'address',
      label: <Trans>Address</Trans>,
    },
    {
      name: 'ic',
      label: <Trans>IC</Trans>,
    },
    {
      name: 'dic',
      label: <Trans>DIC</Trans>,
    },
    {
      name: 'email',
      label: <Trans>E-mail</Trans>,
    },
    {
      name: 'contact_person',
      label: <Trans>Contact person</Trans>,
    },
    {
      name: 'phone',
      label: <Trans>Phone 1</Trans>,
    },
    {
      name: 'phone_2',
      label: <Trans>Phone 2</Trans>,
    },
    {
      name: 'type',
      label: <Trans>Type</Trans>,
    },
    {
      name: 'mark',
      label: <Trans>Mark</Trans>,
    },
  ],
  [
    {
      name: 'customer_no',
      label: <Trans>Customer</Trans>,
    },
    {
      name: 'working_hours_mon_thu',
      label: <Trans>Opening hours Monday/Thursday</Trans>,
    },
    {
      name: 'working_hours_fri',
      label: <Trans>Opening hours Friday</Trans>,
    },
    {
      name: 'working_hours_sat',
      label: <Trans>Opening hours Saturday</Trans>,
    },
    {
      name: 'branch',
      label: <Trans>Branch</Trans>,
    },
    {
      name: 'business_lease',
      label: <Trans>Business lease</Trans>,
    },
    {
      name: 'no_cars_per_day',
      label: <Trans>No. of cars per day</Trans>,
    },
    {
      name: 'no_spare_vehicles',
      label: <Trans>No. of spare cars</Trans>,
    },
    {
      name: 'pref_level',
      label: <Trans>Preference level</Trans>,
    },
    {
      name: 'note',
      label: <Trans>Note</Trans>,
    },
  ],
]

export default columns
