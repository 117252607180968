import AsyncInput from 'component/field/AsyncInput'
import CheckBoxInput from 'component/field/CheckboxInput'
import DatePickerInput from 'component/field/DatePickerInput'
import SelectInput from 'component/field/SelectInput'
import TextInput from 'component/field/TextInput'
import CustomTooltip from 'component/material/CustomTooltip'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {ACC_NAMES, DATE_FORMAT, DB_DATE_FORMAT, ODOMETER_THRESHOLD} from 'helper/constants'
import endpoints from 'helper/endpoints'
import {handleSortByLabel, mapErrorResponseToForm} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {validateEngineCapacity, validatePower, validateRz, validateVin} from 'helper/validations'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Field} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {createCar} from 'redux/action/callAction'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import DriveEtaIcon from '@material-ui/icons/DriveEta'

const CarCard = (props) => {
  const {
    classes,
    formProps,
    rzOrVinDebounce,
    id,
    vinNotFound,
    marksListLoading,
    fleetsListLoading,
    createCar,
    carSearchLoading,
    disabled,
    carData,
  } = props

  const [newCarCreated, setNewCarCreated] = useState(false)
  const [allFleetOptionsData, setAllFleetOptionsData] = useState(false)
  const [allFleetOptionsLoaded, setAllFleetOptionsLoaded] = useState(false)
  const [allMarkOptionsData, setAllMarkOptionsData] = useState(false)
  const [allMarkOptionsLoaded, setAllMarkOptionsLoaded] = useState(false)
  const [carErrors, setCarErrors] = useState({})

  const [prevPower, setPrevPower] = useState(Number(carData.power) || 0)
  const powerEdgeValue = 10 // number after which comes 0 in field
  const [prevEngineCapacity, setPrevEngineCapacity] = useState(Number(carData.engine_capacity) || 0)
  const engineCapacityEdgeValue = 500 // number after which comes 0 in field

  const isNotNew = id !== 'new'

  const loadFleetOptions = async (search, loadedOptions) => {
    if (allFleetOptionsLoaded && search) {
      const filteredOptions = allFleetOptionsData.filter((s) =>
        s.label.toLowerCase().includes(search.toLowerCase())
      )
      return {
        options: filteredOptions?.map((s) => ({
          label: s.label,
          value: s.value,
        })),
        hasMore: false,
      }
    } else {
      let data = [...loadedOptions]
      let hasMore = false
      await httpClient
        .get(endpoints.fleets, {
          limit: 10,
          offset: loadedOptions.length,
        })
        .then((res) => {
          data.push(
            ...res?.data?.objects.map((r) => ({
              value: r.id,
              label: r.name,
            }))
          )
          hasMore = res?.data?.meta?.total_count > loadedOptions.length
          if (!hasMore) {
            setAllFleetOptionsData(handleSortByLabel(data))
            setAllFleetOptionsLoaded(true)
          } else {
            setAllFleetOptionsLoaded(false)
          }
        })
      return {
        options: handleSortByLabel(data),
        hasMore: hasMore,
      }
    }
  }

  const loadMarkOptions = async (search, loadedOptions) => {
    if (allMarkOptionsLoaded && search) {
      const filteredOptions = allMarkOptionsData.filter((s) =>
        s.label.toLowerCase().includes(search.toLowerCase())
      )
      return {
        options: filteredOptions?.map((s) => ({
          label: s.label,
          value: s.value,
        })),
        hasMore: false,
      }
    } else {
      let data = [...loadedOptions]
      let hasMore = false
      await httpClient
        .get(endpoints.marks, {
          limit: 50,
          offset: loadedOptions.length,
        })
        .then((res) => {
          data.push(
            ...res?.data?.objects.map((r) => ({
              value: r.id,
              label: r.name,
            }))
          )
          hasMore = res?.data?.meta?.total_count > loadedOptions.length
          if (!hasMore) {
            setAllMarkOptionsData(handleSortByLabel(data))
            setAllMarkOptionsLoaded(true)
          } else {
            setAllMarkOptionsLoaded(false)
          }
        })

      return {
        options: handleSortByLabel(data),
        hasMore: hasMore,
      }
    }
  }

  const onAsyncChange = (name, value, state) => {
    setCarErrors((prevState) => ({...prevState, [name]: undefined}))
    formProps.form.change(name, value)
    if (name === 'fleet') {
      formProps.form.change('fleet_name', state.label)
    }
  }

  const prepareCarData = () => {
    const values = formProps.values
    let data = {
      vin: values.vin,
      registration_plate: values.registration_plate,
      product_code: values.product_code,
      warranty_date: values.warranty_date
        ? moment(values.warranty_date, DATE_FORMAT).format(DB_DATE_FORMAT)
        : null,
      renter: values.renter,
      mark_id: values.mark,
      model: values.model,
      year_of_manufacture: values.year_of_manufacture,
      fuel: values.fuel,
      power: values.power,
      engine_capacity: values.engine_capacity,
    }

    if (vinNotFound) {
      data.fleet_id = values.fleet
    } else {
      data.premium = values.premium
    }
    return data
  }

  const handleCreateCar = () => {
    const data = prepareCarData(formProps)

    return createCar(data)
      .then((res) => {
        res.fleet_name && formProps.form.change('fleet_name', res.fleet_name)
        setNewCarCreated(true)
      })
      .catch((err) => {
        const parsedError = mapErrorResponseToForm(err)
        const formError = {}
        Object.keys(parsedError).map((e) => (formError[e.replaceAll(/_id/g, '')] = parsedError[e]))
        setCarErrors(formError)
      })
  }

  const getSupplierType = (car = {}) => {
    if (formProps.values.odometer) {
      if (!car.oe_direction) {
        return 'CFS'
      }

      const isProductCodeOl = car.product_code?.toLowerCase() === 'ol'
      const isPremium = car.premium
      const carRenter = car.renter?.toLowerCase()
      const isRenterServBOrOe = carRenter
        ? carRenter.startsWith('serv_b') || carRenter.startsWith('oe')
        : false

      if (isProductCodeOl) {
        if (isPremium || isRenterServBOrOe) {
          return 'OE'
        }
        return 'CFS'
      }

      const isProductCodeFm = car.product_code?.toLowerCase() === 'fm'

      if (isProductCodeFm) {
        const isSpecialAccount =
          car.renter &&
          ACC_NAMES.split(',')
            .map((n) => n.toLowerCase().trim())
            .some((subStr) => carRenter.includes(subStr))

        if (isSpecialAccount) {
          return 'CFS'
        }

        const isLowOdometer = Number(formProps.values.odometer) < ODOMETER_THRESHOLD
        const isRecentManufacture = moment().year() - car.year_of_manufacture <= 3

        if (isPremium || isRenterServBOrOe || isLowOdometer || isRecentManufacture) {
          return 'OE'
        }
      }

      return 'CFS'
    }
    // if field of odometer in form is not filled, display ---
    return '---'
    // const isWarrantyValid = car.warranty_date ? moment(car.warranty_date) > moment() : false
  }

  useEffect(() => {
    setNewCarCreated(false)
  }, [])

  const carDetail = carData?.id ? carData : formProps.values.car

  return (
    <GridItem item xs={6} style={{paddingLeft: 20}}>
      <GridItem
        container
        xs={12}
        style={{
          padding: 20,
          borderRadius: 4,
          backgroundColor: '#dddddd',
          alignSelf: 'flex-start',
        }}
        alignContent={'flex-start'}
      >
        <GridItem container justifyContent={'center'}>
          <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 10}}>
            <Trans>Car card</Trans>
          </Typography>
          {(!vinNotFound || newCarCreated) && carDetail?.id && (
            <Box
              style={{
                display: 'flex',
                gap: 8,
                marginLeft: 8,
                marginBottom: 10,
                alignItems: 'center',
              }}
            >
              <CustomTooltip
                title={
                  carDetail?.is_active ? (
                    <Trans>Car is active</Trans>
                  ) : (
                    <Trans>Car is not active</Trans>
                  )
                }
              >
                <DriveEtaIcon
                  fontSize={'small'}
                  className={
                    carDetail?.is_active ? classes.successColorFill : classes.errorColorFill
                  }
                />
              </CustomTooltip>
              <Typography variant={'body1'} style={{fontWeight: 700}} color={'primary'}>
                {getSupplierType(carDetail)}
              </Typography>
            </Box>
          )}
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || isNotNew || carSearchLoading || newCarCreated}
            name="vin"
            label={<Trans>VIN</Trans>}
            component={TextInput}
            onChange={(e) => {
              rzOrVinDebounce(e, formProps)
              setCarErrors((prevState) => ({...prevState, ['vin']: undefined}))
            }}
            validate={validateVin}
            inputProps={{style: {textTransform: 'uppercase'}}}
            hasSearch={true}
            helperText={carErrors?.vin}
            error={!!carErrors?.vin}
            loading={carSearchLoading}
          />
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || isNotNew || carSearchLoading || newCarCreated}
            name="registration_plate"
            label={<Trans>Registration plate</Trans>}
            component={TextInput}
            validate={validateRz}
            inputProps={{style: {textTransform: 'uppercase'}}}
            helperText={carErrors?.registration_plate}
            error={!!carErrors?.registration_plate}
            onChange={() =>
              setCarErrors((prevState) => ({...prevState, ['registration_plate']: undefined}))
            }
          />
        </GridItem>
        <GridItem
          container
          justifyContent={'center'}
          alignItems={'center'}
          xs={12}
          style={{paddingTop: 10, paddingBottom: 14, minHeight: 40}}
        >
          {vinNotFound ? (
            <Typography
              style={{fontSize: 13, fontWeight: 600}}
              className={newCarCreated ? classes.successColorFill : classes.errorColorFill}
            >
              {newCarCreated ? (
                <Trans>New car created</Trans>
              ) : (
                <Trans>Car not found, fill the fields and create new car.</Trans>
              )}
            </Typography>
          ) : (
            ''
          )}
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || !vinNotFound || newCarCreated}
            name="product_code"
            label={<Trans>CFM mode</Trans>}
            component={TextInput}
            helperText={carErrors?.product_code}
            error={!!carErrors?.product_code}
            onChange={() =>
              setCarErrors((prevState) => ({...prevState, ['product_code']: undefined}))
            }
          />
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || !vinNotFound || newCarCreated}
            name="warranty_date"
            label={<Trans>Warranty date</Trans>}
            component={DatePickerInput}
            placeholder={'dd.mm.yyyy'}
            onChange={() =>
              setCarErrors((prevState) => ({...prevState, ['warranty_date']: undefined}))
            }
            helperText={carErrors?.warranty_date}
            error={carErrors?.warranty_date}
          />
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || !vinNotFound || newCarCreated}
            name="renter"
            label={<Trans>Renter</Trans>}
            component={TextInput}
            helperText={carErrors?.renter}
            error={!!carErrors?.renter}
            onChange={() => setCarErrors((prevState) => ({...prevState, ['renter']: undefined}))}
          />
        </GridItem>

        {disabled || newCarCreated || !vinNotFound ? (
          <GridItem container xs={12}>
            <Field
              disabled={true}
              name="fleet_name"
              label={<Trans>Fleet</Trans>}
              component={TextInput}
              helperText={carErrors?.fleet}
              error={!!carErrors?.fleet}
            />
          </GridItem>
        ) : (
          <GridItem container xs={12} style={{marginBottom: 12}}>
            <AsyncInput
              name="fleet"
              label={<Trans>Fleet</Trans>}
              isSearchable={true}
              loadOptions={loadFleetOptions}
              onChange={onAsyncChange}
              disabled={formProps.submitting}
              loading={fleetsListLoading}
              showHelperText={true}
              filters={false}
              error={carErrors?.fleet}
            />
          </GridItem>
        )}
        {vinNotFound ? (
          <GridItem container xs={12} style={{marginBottom: 12}}>
            <AsyncInput
              name="mark"
              label={<Trans>Mark</Trans>}
              isSearchable={true}
              loadOptions={loadMarkOptions}
              onChange={onAsyncChange}
              disabled={disabled || newCarCreated}
              loading={marksListLoading}
              showHelperText={true}
              filters={false}
              error={carErrors?.mark}
            />
          </GridItem>
        ) : (
          <GridItem container xs={12}>
            <Field
              disabled={disabled || !vinNotFound || newCarCreated}
              name="mark"
              label={<Trans>Mark</Trans>}
              component={TextInput}
              helperText={carErrors?.mark}
              error={!!carErrors?.mark}
              onChange={() => setCarErrors((prevState) => ({...prevState, ['mark']: undefined}))}
            />
          </GridItem>
        )}
        <GridItem container xs={12}>
          <Field
            disabled={disabled || !vinNotFound || newCarCreated}
            name="model"
            label={<Trans>Model</Trans>}
            component={TextInput}
            helperText={carErrors?.model}
            error={!!carErrors?.model}
            onChange={() => setCarErrors((prevState) => ({...prevState, ['model']: undefined}))}
          />
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || !vinNotFound || newCarCreated}
            name="year_of_manufacture"
            label={<Trans>Year of manufacture</Trans>}
            component={TextInput}
            type={'number'}
            helperText={carErrors?.year_of_manufacture}
            error={!!carErrors?.year_of_manufacture}
            onChange={() =>
              setCarErrors((prevState) => ({...prevState, ['year_of_manufacture']: undefined}))
            }
            inputProps={{min: 1960, max: moment().year(), step: 1}}
          />
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || !vinNotFound || newCarCreated}
            name="fuel"
            label={<Trans>Fuel</Trans>}
            component={SelectInput}
            options={['Petrol', 'Diesel', 'LPG', 'CNG', 'EL', 'Hybrid', 'Hydrogen']?.map(
              (fuel) => ({
                name: fuel,
                value: fuel,
              })
            )}
          />
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || !vinNotFound || newCarCreated}
            name="power"
            label={<Trans>Power</Trans>}
            component={TextInput}
            type={'number'}
            error={!!carErrors?.power}
            onChange={(e) => {
              setCarErrors((prevState) => ({...prevState, ['power']: undefined}))

              if (e.target.value > 1000) {
                formProps.form.change('power', 1000)
                setPrevPower(1000)
              } else if (prevPower >= powerEdgeValue && e.target.value < powerEdgeValue) {
                formProps.form.change('power', 0)
                setPrevPower(0)
              } else if (prevPower === 0 && Number(e.target.value) === 1) {
                formProps.form.change('power', powerEdgeValue)
                setPrevPower(powerEdgeValue)
              } else {
                setPrevPower(Number(e.target.value))
              }
            }}
            inputProps={{min: 0, max: 1000, step: 1}}
            helperText={carErrors?.power}
            inputJustifyRight={true}
            validate={validatePower}
            onFocus={(e) => e.target.select()}
          />
        </GridItem>
        <GridItem container xs={12}>
          <Field
            disabled={disabled || !vinNotFound || newCarCreated}
            name="engine_capacity"
            label={<Trans>Engine capacity</Trans>}
            component={TextInput}
            type={'number'}
            error={!!carErrors?.engine_capacity}
            onChange={(e) => {
              setCarErrors((prevState) => ({...prevState, ['engine_capacity']: undefined}))

              if (e.target.value > 9999) {
                formProps.form.change('engine_capacity', 9999)
                setPrevEngineCapacity(9999)
              } else if (
                prevEngineCapacity >= engineCapacityEdgeValue &&
                e.target.value < engineCapacityEdgeValue
              ) {
                formProps.form.change('engine_capacity', 0)
                setPrevEngineCapacity(0)
              } else if (prevEngineCapacity === 0 && Number(e.target.value) === 1) {
                formProps.form.change('engine_capacity', engineCapacityEdgeValue)
                setPrevEngineCapacity(engineCapacityEdgeValue)
              } else {
                setPrevEngineCapacity(Number(e.target.value))
              }
            }}
            inputProps={{min: 0, max: 9999, step: 1}}
            helperText={carErrors?.engine_capacity}
            inputJustifyRight={true}
            validate={validateEngineCapacity}
            onFocus={(e) => e.target.select()}
          />
        </GridItem>
        {!vinNotFound && (
          <GridItem container xs={12}>
            <Field
              disabled={true}
              name="premium"
              label={<Trans>Premium</Trans>}
              component={CheckBoxInput}
              type={'checkbox'}
            />
          </GridItem>
        )}
        <GridItem justifyContent={'flex-end'} container xs={12} style={{marginTop: 20}}>
          {vinNotFound && !newCarCreated && (
            <PrimaryButton
              fullWidth={false}
              onClick={handleCreateCar}
              size={'small'}
              textPadding={0}
              text={<Trans>Create new car</Trans>}
              disabled={disabled}
            />
          )}
        </GridItem>
      </GridItem>
    </GridItem>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createCar,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      marksListLoading: store.call.marksListLoading,
      fleetsListData: store.call.fleetsListData,
    }
  }, mapDispatchToProps)
)(CarCard)
